class ScrollSpy {
  constructor(elements) {
    this.observableSections = elements;
    this.targets = [];
    this.observer = null;
    this.refresh();
  }

  refresh() {
    this.observer = this.getNewObserver();
    this.observableSections.forEach((item) => {
      const scrollspyTarget = item?.dataset?.scrollspyTarget;
      const element = scrollspyTarget
        ? document.getElementById(scrollspyTarget)
        : null;

      if (element) {
        this.targets.push({ active: false, id: scrollspyTarget });
        this.observer.observe(element);
      }
    });
  }

  dispose() {
    this.observer.disconnect();
  }

  getNewObserver() {
    const options = {
      root: null,
      threshold: [0.1, 0.5, 1],
      rootMargin: "0px",
    };

    return new IntersectionObserver(
      (entries) => this.observerCallback(entries),
      options
    );
  }

  observerCallback(entries) {
    entries.forEach((entry) => {
      this.targets.forEach((target) => {
        if (entry.target.id === target.id) {
          target.active = entry.isIntersecting;
        }
      });
    });

    this.process();
  }

  process() {
    let active = false;
    this.targets.forEach((target, index) => {
      const el = this.observableSections[index];

      if (!active && target.active) {
        el.classList.add("active");
        active = true;
      } else {
        el.classList.remove("active");
      }
    });
  }
}

export default ScrollSpy;
